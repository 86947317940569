import React, {useEffect, useState, useRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {CSSTransition} from 'react-transition-group';
import Picture from '../../../common/Picture';
import {NexacroJsonUnitType} from "../../../../components/model/SqciTypes";
import * as gfn from "../../../../components/utils/CommonUtils";
import useGlobalData from "../../../../hooks/useGlobalData";
import {fn_comma, fn_uncomma} from "../../../../components/utils/CommonUtils";
import { isMobile, isAndroid, isIOS, CustomView, isSamsungBrowser } from 'react-device-detect';//2024-07-17 huimei.zhu  GHCSEC-3998 [SQCI mobile ] The Keyboards without icons " - "
import {toNumber} from "lodash";

export default function QmvDetailInspectDetail(
        {state, ds_Part, ds_Spec, ds_grdColList, onClose, selected, fn_changDs, fn_setSpecGrid_N}:
        {
            state: string,
            ds_Part: NexacroJsonUnitType[],
            ds_Spec: NexacroJsonUnitType[],
            ds_grdColList: NexacroJsonUnitType[],
            onClose: () => void,
            selected?: number,
            fn_changDs:(datasetName: string, dataset: Array<NexacroJsonUnitType>) => void,
            fn_setSpecGrid_N:(sgIndex:number, sgBoolean:boolean, sgDs_Spec:Array<NexacroJsonUnitType>, sgDs_Part:Array<NexacroJsonUnitType>) => Array<NexacroJsonUnitType>
        }
    ) {
    const {getDomain} = useGlobalData();

    const [imagePath, setImagePath] = useState<string[]>();
    const [showPicture, setShowPicture] = useState<boolean>(false);

    const [selectedIdx, setSelectedIdx] = useState(Number(selected) + 1);

    // const inputRef = useRef<any>([]);
    const targets = useRef<{ index: number; index2: number; el: HTMLInputElement}[]>([]);

    let ds_Decision02: NexacroJsonUnitType[] = [{ CODE : 'Y', NAME : 'OK' },{ CODE : 'N', NAME : 'NG' }];

    const goPictureView = (path: string[]) => {
        setImagePath(path);
        setShowPicture(false);
    };

    const closePicture = () => {
        setShowPicture(false);
        setImagePath(undefined);
    };

    useEffect(() => {
        if(imagePath) {
            setShowPicture(true);
        }
    }, [imagePath]);

    useEffect(() => {
    }, []);

    const handleChange = (e: any, index :number, columnN :string) => {
        let value = e.target.value;
        let copyArray = [...ds_Spec];
        copyArray[index][columnN] = value;
        // copyArray[index] = {...copyArray[index], columnN: e.target.value};

        copyArray = fn_setSpecGrid_N(index,false,copyArray, [...ds_Part]);
        fn_changDs('ds_Spec', copyArray);
    }

    const handleBlur = (e: any, index :number, columnN :string) => {
        let value = e.target.value;
        let copyArray = [...ds_Spec];
        copyArray[index][columnN] = value;
        // copyArray[index] = {...copyArray[index], columnN: e.target.value};

        copyArray = fn_setSpecGrid_N(index,false,copyArray, [...ds_Part]);
        fn_changDs('ds_Spec', copyArray);
    }

    // 콤마 처리
    const handleNumberBlur = (e: any, index :number, index2 :number, columnN :string) => {
        //2024-07-17 huimei.zhu  GHCSEC-3998 [SQCI mobile ] The Keyboards without icons " - "
        const v = e.target.value.replaceAll(',', '');//2024-07-24 zilong.ge GHCSEC-3998 Modify multiple commas that cannot be deleted.
        const cntVal = inputChg(v, ds_Spec[index]['MEAS_VAL_'+(index2+1)] as String);
        const val = gfn.fn_uncomma(cntVal);
        
        if (targets && targets.current.length > 0) {
            const currentTarget = targets.current.find(
                (target) => target.index === index && target.index2 === index2
            );
            if (currentTarget) currentTarget.el.value = gfn.fn_comma(cntVal);
             //if (currentTarget) currentTarget.el.value = val;
        }

        let copyArray = [...ds_Spec];
        copyArray[index][columnN] = val;
        // copyArray[index] = {...copyArray[index], columnN: e.target.value};

        copyArray = fn_setSpecGrid_N(index,false,copyArray, [...ds_Part]);
        fn_changDs('ds_Spec', copyArray);
    }
//2024-07-17 huimei.zhu  GHCSEC-3998 [SQCI mobile ] The Keyboards without icons " - " start
    const inputChg = (str:String, oldVal:String) =>{
        let val = String(str);
        if (isSamsungBrowser && val.startsWith('.')) {
            val = val.replace('.','-');
        }

        //2024-07-24 zilong.ge GHCSEC-3998 Add 01, 001, 00.1 and - 01 - 00, 1-0 0.1 scenario processing
        if (/^0(\d+)/g.test(val) || /^-0(\d+)/g.test(val)) {
            return String(toNumber(val)) || '';
        }

        const regx = /^(\-)?\d+(\.?)(\d+)?$/g
        if(/^(\-)?$/g.test(val) || regx.test(val)) {
            return val;
        } else {
            // return val.substring( 0, val.length - 1 );
            return oldVal || '';
        }
    };
    const onInputBlur=(e:React.ChangeEvent<HTMLInputElement>, index :number, index2 :number, columnN :string)=> {
        let val = e.target.value;
        if (val.endsWith('.') || val.endsWith('-')) {
            val = val.substring(0, val.length - 1)
        }

        e.target.value = val;

        //2024-07-24 zilong.ge GHCSEC-3998 Add 0.0 00 - 0 - 0.0 scenario processing
        let numberVal = toNumber(e.target.value.replaceAll(',', ''));
        if (isNaN(numberVal) || val === '') { // toNumber(‘’) = 0 so add [val === '']
            e.target.value = '';
        } else {
            if (numberVal === 0) {
                e.target.value = '0';
            }
        }
        let copyArray = [...ds_Spec];
        copyArray[index][columnN] = e.target.value;//2024-07-24 zilong.ge GHCSEC-3998 Modification of assignment errors
        // copyArray[index] = {...copyArray[index], columnN: e.target.value};

        copyArray = fn_setSpecGrid_N(index,false,copyArray, [...ds_Part]);
        fn_changDs('ds_Spec', copyArray);
    };
//2024-07-17 huimei.zhu  GHCSEC-3998 [SQCI mobile ] The Keyboards without icons " - " end 

    // 입력 할 시 콤마 자동 제거 처리
   // const handleUnComma = (e: any, index :number, index2 :number, columnN :string) => {
        // const val = gfn.fn_uncomma(e.target.value);
        
        // if (targets && targets.current.length > 0) {
        //     const currentTarget = targets.current.find(
        //         (target) => target.index === index && target.index2 === index2
        //     );
        //     if (currentTarget) currentTarget.el.value = val;
        // }
   // }

    // 23.09.02 엔터 시 하단으로 이동 처리
    // "div_Main_div_Detail_grd_DetailList_onkeyup" 대체
    const handleKeyPress = (e:any, index:number, index2:number) => {
        if (e.key === 'Enter') {
            if (targets && targets.current.length > 0) {
                const currentTarget = targets.current.find(
                    (target) => target.index === index && target.index2 === (index2+1)
                );
                if (currentTarget) currentTarget.el.focus();
            }
        }
    }

    const [swiper, setSwiper] = useState<any>();

    function lastHidden() {
        // 마지막 입력칸일 경우 포커스를 다음페이지로 이동
        const link: HTMLElement = document.getElementsByClassName('swiper-next')[0] as HTMLElement;
        link.click();
    }

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{getDomain('DOMAIN2604')}</h1>  {/*출하검사 성적서 작성*/}
                        <span className={'btn-wrap right'}>
                            <button type={'button'} className={'button-close'} onClick={onClose}>
                                <i className={'hidden'}>{getDomain('DOMAIN0173')}</i>   {/*닫기*/}
                            </button>
                        </span>
                    </header>
                    <div className={'container-noheader'}>
                        <div className={'comm-slide-wrap'} style={{
                            display: 'block',
                            // position: 'absolute',
                            backgroundColor: 'white',
                            width: '100%',
                            height: 'unset'
                        }}>
                            <div className={'swiper-pagination'} style={{
                                width: '100% !important',
                                paddingBottom: '2rem'
                            }}></div>
                            <div className="pagination-type2"><em>{selectedIdx}</em>/<span>{ds_Spec.length}</span></div>

                            {/*23.11.06 네비게이션 기능 추가*/}
                            <div className={'swiper-next'}></div>
                            <div className={'swiper-prev'}></div>
                            <Swiper
                                modules={[Pagination, Navigation]}
                                pagination={{
                                    dynamicBullets: true,
                                    // type: 'bullets',
                                    el: '.swiper-pagination',
                                    clickable: true
                                }}
                                autoHeight={true}
                                // onSwiper={swiper => console.log(swiper)}
                                // onSlideChange={() => console.log('slide change')}
                                onSlideChange={(swiper) => setSelectedIdx(swiper.activeIndex + 1)}
                                initialSlide={selected || 0}
                                style={{marginBottom:'230px'}}
                                className={'swiper mySwiper'}
                                navigation={{
                                    nextEl: ".swiper-next",
                                    prevEl: ".swiper-prev",
                                }}
                            >
                                {
                                    ds_Spec.map((item, index) => {
                                        return <SwiperSlide key={'swiper_'.concat(String(item.PARA_CD)).concat(String(index))}>
                                            <div className={'swiper-slide'}>
                                                <ul className={'contents-table-list'}>
                                                    <li>
                                                        <div className={'col'}>
                                                            <span className={'title'}>{getDomain('DOMAIN4758')}</span>    {/*검사항목*/}
                                                            <span className={'item-wrap'}>{item.PARA_NM}
                                                                {!gfn.gfn_isNull(item.ATTACH_FILE_ID) ?
                                                                    <button type={'button'} className={'btn-gallary'} onClick={() => goPictureView([
                                                                        process.env.REACT_APP_HTTP_BASE_URL+'/common/nexacro/commonGetImageFile.do?fileId='+item.ATTACH_FILE_ID
                                                                    ])}>
                                                                        <i className={'hidden'}>이미지</i>
                                                                    </button> : <></>
                                                                }
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className={'col'}>
                                                            <span className={'title'}>{getDomain('DOMAIN4631')}</span>  {/*검사방법*/}
                                                            <span className={'item-wrap'}>{item.MEQUIP_NM}</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className={'col'}>
                                                            <span className={'title'}>LSL</span>
                                                            <span className={'item-wrap right'}>{item.LSL}</span>
                                                        </div>
                                                        <div className={'col'}>
                                                            <span className={'title'}>USL</span>
                                                            <span className={'item-wrap right'}>{item.USL}</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className={'col'}>
                                                            <span className={'title'}>CL</span>
                                                            <span className={'item-wrap right'}>{item.CL}</span>
                                                        </div>
                                                        <div className={'col'}>
                                                            <span className={'title'} dangerouslySetInnerHTML={{__html:getDomain('DOMAIN4632')}}></span>  {/*측정단위*/}
                                                            <span className={'item-wrap right'}>{item.UNIT}</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className={'table-wrap'}>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{width: '20%'}}/>
                                                            <col/>
                                                        </colgroup>
                                                        <thead>
                                                        <tr>
                                                            <th scope={'col'}>{getDomain('DOMAIN4608')} {/*No*/}</th>
                                                            <th scope={'col'}>
                                                                <strong className={'required'}>*</strong> {getDomain('DOMAIN4633')} {/*측정결과*/}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            Array.from({length: Number(item.SAM_CNT)}, (v,i) => i).map((item2, index2) => {
                                                                return <React.Fragment key={'swiperD_'.concat(String(item2))}>
                                                                    {state === "INFO" ?
                                                                        <tr>
                                                                            <td>{index2+1}</td>
                                                                            <td className={'center'}>
                                                                                {(item.CO_YN !== 'Y') ?
                                                                                    (item.NUM_YN === 'Y') ?
                                                                                        (isIOS ?
                                                                                            <input type={'text'} className={'result-right'} defaultValue={gfn.fn_comma(gfn.gfn_tostring(item['MEAS_VAL_'+(index2+1)]))}
                                                                                               ref={(el:HTMLInputElement) => targets.current.push({ index: index, index2: index2 , el: el})}
                                                                                               onInput={(e) => {handleNumberBlur(e, index, index2, 'MEAS_VAL_'+(index2+1))}}
                                                                                               onKeyPress={(e) => handleKeyPress(e,index, index2)}
                                                                                               pattern={'-?\d\*.?\d+'}
                                                                                               onBlur={e => onInputBlur(e, index, index2, 'MEAS_VAL_'+(index2+1))}
                                                                                            />
                                                                                        : <input type={'text'} className={'result-right'} defaultValue={gfn.fn_comma(gfn.gfn_tostring(item['MEAS_VAL_'+(index2+1)]))}
                                                                                            ref={(el:HTMLInputElement) => targets.current.push({ index: index, index2: index2 , el: el})}
                                                                                            onInput={(e) => {handleNumberBlur(e, index, index2, 'MEAS_VAL_'+(index2+1))}}
                                                                                            onKeyPress={(e) => handleKeyPress(e,index, index2)}
                                                                                            inputMode={"numeric"}
                                                                                            onBlur={e => onInputBlur(e, index, index2, 'MEAS_VAL_'+(index2+1))}
                                                                                            />)
                                                                                        :
                                                                                        <div className={'radio-item'}>
                                                                                            {
                                                                                                ds_Decision02.map((a, aindex) => {
                                                                                                    /*return <option key={a.CODE} value={a.CODE as string}>{a.NAME}</option>*/
                                                                                                    return <label key={a.CODE}><input type="radio" value={a.CODE as string} checked={item['MEAS_VAL_'+(Number(index2)+1)] as string == a.CODE} onChange={(e) => {handleChange(e, index, 'MEAS_VAL_'+(index2+1))}}/>{a.NAME}</label>
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    :
                                                                                        <input type={'text'} className={'result-right'} defaultValue={item['MEAS_VAL_'+(index2+1)] as string}
                                                                                           readOnly={true}/>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        <tr>
                                                                            <td>{index2+1}</td>
                                                                            <td className={'center'}>
                                                                                {
                                                                                    (item.NUM_YN === 'Y') ?
                                                                                    <input type={'number'} className={'result-right'} defaultValue={item['MEAS_VAL_'+(index2+1)] as string}
                                                                                           ref={(el:HTMLInputElement) => targets.current.push({ index: index, index2: index2 , el: el})}
                                                                                           disabled={true}
                                                                                    />
                                                                                    :
                                                                                    <div className={'radio-item'}>
                                                                                        {
                                                                                            ds_Decision02.map((a, aindex) => {
                                                                                                /*return <option key={a.CODE} value={a.CODE as string}>{a.NAME}</option>*/
                                                                                                return <label key={a.CODE}>
                                                                                                    <input type="radio"
                                                                                                           disabled
                                                                                                           value={a.CODE as string}
                                                                                                           checked={item['MEAS_VAL_'+(Number(index2)+1)] as string == a.CODE}
                                                                                                           onChange={(e) => {handleChange(e, index, 'MEAS_VAL_'+(index2+1))}}
                                                                                                    />{a.NAME}
                                                                                                </label>
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </React.Fragment>
                                                            })
                                                        }
                                                        {
                                                            ds_Spec.length != (index + 1)
                                                                ?
                                                                <input type={"text"} onFocus={lastHidden} style={{border: "0px solid #FFF", width:"1px", height:"1px"}} />
                                                                : null
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} onClick={onClose}>{getDomain('DOMAIN0173')}</button>   {/*닫기*/}
                        </div>
                    </div>
                </div>
            </div>
            <CSSTransition in={showPicture} classNames={showPicture ? 'right' : 'left'} timeout={500} unmountOnExit>
                <Picture pathList={imagePath || []} onClose={closePicture}/>
            </CSSTransition>
        </>
    );
};