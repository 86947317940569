/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 성적서 (세부검사정보)
 * 02. 프로그램ID : QmsDetailInspectDetail.tsx
 * 03. 프로그램명 : 입고검사 성적서 작성 (세부검사정보 탭 > 상세입력화면)
 * 04. 화면설명 : 입고검사 성적서 작성
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {CSSTransition} from 'react-transition-group';
import Picture from '../../../common/Picture';
import useHtmlElement from "../../../../hooks/useHtmlElement";
import {NexacroJsonType, NexacroJsonUnitType, SelectBoxType} from "../../../../components/model/SqciTypes";
import {useDataset} from "../../../../components/contexts/CommonContext";
import {STORAGE_NAME} from "../../../../components/CommonConstants";
import useGlobalData from "../../../../hooks/useGlobalData";
import {gfn_isNull} from "../../../../components/utils/CommonUtils";
import useAxios, {AXIOS_HEADER} from "../../../../hooks/useAxios";
import * as gfn from "../../../../components/utils/CommonUtils";
import {useError} from "../../../../hooks/useException";
import { isMobile, isAndroid, isIOS, CustomView, isSamsungBrowser } from 'react-device-detect'; //2024-07-17 huimei.zhu  GHCSEC-3998 [SQCI mobile ] The Keyboards without icons " - "
import {toNumber} from "lodash";

export default function QmsDetailInspectDetail({    list,
                                                    ds_grdColList,
                                                    onClose,
                                                    selected,
                                                    pv_flag,
                                                    ds_AllSample,
                                                    ds_Aql,
                                                    ds_List,
                                                    setFv_maxSamCnt,
                                                    ds_Defect,
                                                    ds_DefectDelete,
                                                    setDataset
                                               }:
                                               {    list: any[],
                                                    ds_grdColList: any[],
                                                    onClose: (inspData: any[]) => void,
                                                    selected?: number,
                                                    pv_flag?: String,
                                                    ds_AllSample?: any[],
                                                    ds_Aql?: any[],
                                                    ds_List?: any[],
                                                    setFv_maxSamCnt:  (nMaxSam: number) => void,
                                                    ds_Defect?: any[],
                                                    ds_DefectDelete?: any[],
                                                    setDataset: (datasetName: string, vds_DefectSymptom2: any[]) => void,
                                               }) {

    const {useSelectBox, useInput} = useHtmlElement();
    const {dataset, sessionValues} = useDataset();
    const {getGlobalSetting, setGlobalSetting} = useGlobalData();
    const gv_locale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {sendAxios} = useAxios();
    const {getDomain} = useGlobalData();
    const {throwException} = useError();

    const [imagePath, setImagePath] = useState<string[]>();
    const [showPicture, setShowPicture] = useState<boolean>(false);
    const [selectedIdx, setSelectedIdx] = useState(Number(selected) + 1);
    const [inspData, setInspData] = useState(list);
    let inspDatas = list;

    const fv_isDivisionQ = sessionValues?.DIVISION_CD == "Q" || sessionValues?.WORK_GROUP_CD == 7 ? true : false; // 무선사업부 여부
    const targets = useRef<{ index: number; index2: number; el: HTMLInputElement}[]>([]);

    let ds_Decision02: NexacroJsonUnitType[] = [{ CODE : '1', NAME : 'OK' },{ CODE : '0', NAME : 'NG' }];
    let vds_AllSample = ds_AllSample||[];
    let vds_Aql = ds_Aql||[];
    let vds_Part = ds_List||[];
    let fv_Qty = Number(vds_Part[0]['GR_QTY']);
    let vds_Defect = ds_Defect||[];
    let vds_DefectDelete = ds_DefectDelete||[];
    let vds_grdColList = ds_grdColList||[];
    const [defaultSecMeasRslt, setDefaultSecMeasRslt] = useState((dataset?.ds_CommonCode || []).filter((i, index) => i.CATEGORY === "SQCI000127" && i.LANG === getGlobalSetting<string>(STORAGE_NAME.LANGUAGE)));
    let [secMeasRslt, setSecMeasRslt] = useState(new Array<NexacroJsonUnitType>());
    let vds_DefectGroup: any = [];
    let vds_DefectSymptom2: any = [];

    const goPictureView = (path: string[]) => {
        setImagePath(path);
        setShowPicture(false);
    };

    const closePicture = () => {
        setShowPicture(false);
        setImagePath(undefined);
    };

    useEffect(() => {
        if(imagePath) {
            setShowPicture(true);
        }
    }, [imagePath]);

    useEffect(() => {
        form_onload();
    }, []);

    useEffect(() => {
        inspDatas = inspData
    }, [inspData]);

    /* form_onload */
    function form_onload() {
        // console.log("세부검사정보 탭 > 상세입력화면");
        // console.log("inspData ↓");
        // console.log(inspData);
        // console.log("세부검사정보 탭 > 상세입력화면");
    }

    /* 검사방식 변경 시 */
    const changeInspMth = (e: any) => {
        let sam = 0;

        // 1. AQL 콤보 변경
        inspDatas[selectedIdx - 1]['SEC_INSP_MTH_F'] = e.target.value.substr(0, 1);
        inspDatas[selectedIdx - 1]['SEC_AQL_LEVEL'] = "";

        // AQL 이 없으므로 검사방식에 따라 sample count 측정
        sam = fn_getSampleCntByMeasMth(e.target.value);

        if (e.target.value.substr(0, 1) == "S" || e.target.value.substr(0, 1) == "G") {
            sam = fn_getSampleCntByAql('', sam);
        }

        fn_redrawGrid(selectedIdx - 1, sam); // 그리드 다시 그린다

        fn_checkMeasCheck();

        /* 변경값 저장 */
        setInspValData(e, '');

        e.target.blur();
    };

    /* AQL 변경 시 */
    const changeInspAql = (e: any) => {
        let sam = 0;
        let samAql = 0;
        sam = fn_getSampleCntByMeasMth(inspDatas[selectedIdx - 1]['SEC_INSP_MTH']);
        samAql = fn_getSampleCntByAql(e.target.value, sam);

        let specId = inspDatas[selectedIdx - 1]['SPEC_ID'];

        fn_redrawGrid(selectedIdx - 1, samAql);

        fn_checkMeasCheck();

        /* 변경값 저장 */
        setInspValData(e, '');

        e.target.blur();
    };

    /* 측정결과 입력 시 */
    const changeInspVal = (e: any, index :number, index2 :number, columnN :string) => {
        //2024-07-17 huimei.zhu  GHCSEC-3998 [SQCI mobile ] The Keyboards without icons " - "
        const v = e.target.value.replaceAll(',', '');//2024-07-24 zilong.ge GHCSEC-3998 Modify multiple commas that cannot be deleted.
        const cntVal = inputChg(v, inspData[index]['MEAS_VAL_'+(index2+1)]);
        const val = gfn.fn_uncomma(cntVal);
        
        
        if (targets && targets.current.length > 0) {
            const currentTarget = targets.current.find(
                (target) => target.index === index && target.index2 === index2
            );
            if (currentTarget) currentTarget.el.value = gfn.fn_comma(val);
        }

        /* 변경값 저장 */
        setInspValData(e, val);

        // SPEC 일반 입력값 입력시
        ds_Spec_onvaluechanged();

        /* 변경값 저장 */
        setInspValData(e, val);
    };
    //2024-07-17 huimei.zhu  GHCSEC-3998 [SQCI mobile ] The Keyboards without icons " - " start
    const inputChg = (str:String, oldVal:String) =>{
        let val = String(str);
        if (isSamsungBrowser && val.startsWith('.')) {
            val = val.replace('.','-');
        }

        //2024-07-24 zilong.ge GHCSEC-3998 Add 01, 001, 00.1 and - 01 - 00, 1-0 0.1 scenario processing
        if (/^0(\d+)/g.test(val) || /^-0(\d+)/g.test(val)) {
            return String(toNumber(val)) || '';
        }

        const regx = /^(\-)?\d+(\.?)(\d+)?$/g
        if(/^(\-)?$/g.test(val) || regx.test(val)) {
            return val;
        } else {
            // return val.substring( 0, val.length - 1 );
            return oldVal || '';
        }
    };
    const onInputBlur=(e:React.ChangeEvent<HTMLInputElement>)=> {
        let val = e.target.value;
        if (val.endsWith('.') || val.endsWith('-')) {
            val = val.substring(0, val.length - 1)
        }

        e.target.value = val;
        //2024-07-24 zilong.ge GHCSEC-3998 Add 0.0 00 - 0 - 0.0 scenario processing
        let numberVal = toNumber(e.target.value.replaceAll(',', ''));
        if (isNaN(numberVal) || val === '') { // toNumber(‘’) = 0 so add [val === '']
            e.target.value = '';
        } else {
            if (numberVal === 0) {
                e.target.value = '0';
            }
        }
        setInspValData(e, e.target.value);//2024-07-24 zilong.ge GHCSEC-3998 Modification of assignment errors
        ds_Spec_onvaluechanged();
    };
//2024-07-17 huimei.zhu  GHCSEC-3998 [SQCI mobile ] The Keyboards without icons " - " end
    // SPEC 일반 입력값 입력시
    function ds_Spec_onvaluechanged() {
        // 필요 없는거 같아서 주석
        // let idx = this.gfn_split(e.columnid, "MEAS_VAL_")[1];
        //
        // if(this.ds_Spec.getColumn(e.row, "NUM_YN") == "N" && this.fv_excelUploadFlag == false){
        //     this.ds_Spec.set_enableevent(false);
        //     for(var i= idx-1 ; i<this.ds_Spec.getColumn(e.row, "SAM_CNT"); i++){
        //         this.ds_Spec.setColumn(e.row, "MEAS_VAL_"+(i+1) , e.newvalue);
        //         this.ds_Spec.setColumn(e.row, "RESULT_YN_"+(i+1) , e.newvalue);
        //         if( e.newvalue != "1"){ //NG 입력시에는 탐색하지 않음
        //             break;
        //         }
        //         if( this.gfn_isNull(this.ds_Spec.getColumn(e.row, "MEAS_VAL_"+(i+2)))){ // 다음 값이 없으면 동일한 값 저장
        //             this.ds_Spec.setColumn(e.row, "MEAS_VAL_"+(i+2), e.newvalue);
        //             this.ds_Spec.setColumn(e.row, "RESULT_YN_"+(i+2) , e.newvalue);
        //         }
        //     }
        //     this.fn_setResultSpec(e.row);
        //     this.ds_Spec.set_enableevent(true);
        //     return;
        // }

        if (inspDatas[selectedIdx - 1]['NUM_YN'] == "N") {
            fn_setResultSpec(selectedIdx - 1);

            return;
        }

            //LSL, USL 과 비교
        //1. LSL 만 존재, 2. USL 만 존재 3. LSL, USL 둘다 존재 4. LSL, USL 둘다 없음
        let nLsL = Number(inspDatas[selectedIdx - 1]['LSL']);
        let nCL = Number(inspDatas[selectedIdx - 1]['CL']);
        let nUsL = Number(inspDatas[selectedIdx - 1]['USL']);

        let sResult = "";   // 판정결과
        let nDefectCount = 0;   //불량수
        let nPassCount = 0;     //OK 수
        let nTotal = 0; // 측정값 합계
        let nAvg = 0;   // 측정값 평균
        let nMin = 0 ;  // 측정값 Min
        let nMax = 0 ;  // 측정값 Max
        let nPassSpecCnt = 0; // 통과한 SPEC 의 합계
        let aMeansArray = [];
        let nInputCnt = 0 ; // 입력된 값들의 합계
        let inspTotalCnt = 0; // 검사수


        //row 의 전체 cell 검사
        for (let i = 0; i < Number(inspDatas[selectedIdx - 1]['SAM_CNT']); i++) {
            let nMeas = parseInt(inspDatas[selectedIdx - 1][vds_grdColList[i]['BIND_COL']]);  //측정값

            /** VOC 31 Start : 2019.04.29 채보람 숫자입력값 복사 기능 개선 **/
            if (inspDatas[selectedIdx - 1][vds_grdColList[i]['BIND_COL']] != undefined && inspDatas[selectedIdx - 1][vds_grdColList[i]['BIND_COL']] != "") {
                let originStr = inspDatas[selectedIdx - 1][vds_grdColList[i]['BIND_COL']];
                nMeas = Number(originStr.replace(/,/gi, ""));
                // inspDatas[selectedIdx - 1][vds_grdColList[i]['BIND_COL']] = fn_comma(originStr, e.row, i + 1, ""); // 콤마 삭제후에 다시 넣는것인데 필요 없을거 같음
            }
            /** VOC 31 End   : 2019.04.29 채보람 숫자입력값 복사 기능 개선 **/

            if (!isNaN(nMeas)) {
                aMeansArray.push(nMeas);
                nTotal += nMeas;
                nInputCnt++;
                let colId = "RESULT_YN_" + (i + 1);

                if (isNaN(nLsL) && isNaN(nUsL)) { //둘다 없음
                    return;
                } else if(!isNaN(nLsL) && !isNaN(nUsL)) { //둘다 존재
                    if (nMeas >= nLsL && nMeas <= nUsL) {
                        nPassCount++;
                        inspDatas[selectedIdx - 1][colId] = "1";
                        inspTotalCnt++;
                    } else {
                        nDefectCount++;
                        inspDatas[selectedIdx - 1][colId] = "0";
                        inspTotalCnt++;
                    }
                } else if(isNaN(nLsL) && !isNaN(nUsL) ) { //USL 만 존재
                    if (nMeas <= nUsL) {
                        nPassCount++;
                        inspDatas[selectedIdx - 1][colId] = "1";
                        inspTotalCnt++;
                    } else {
                        nDefectCount++;
                        inspDatas[selectedIdx - 1][colId] = "0";
                        inspTotalCnt++;
                    }
                } else if(!isNaN(nLsL) && isNaN(nUsL)) {  //LSL 만 존재
                    if (nMeas >= nLsL) {
                        nPassCount++;
                        inspDatas[selectedIdx - 1][colId] = "1";
                        inspTotalCnt++;
                    } else {
                        nDefectCount++;
                        inspDatas[selectedIdx - 1][colId] = "0";
                        inspTotalCnt++;
                    }
                }
            }
        }

        inspDatas[selectedIdx - 1]['INSP_TOTAL_CNT'] = inspTotalCnt; // 검사수
        inspDatas[selectedIdx - 1]['MEAS_DEFECT_CNT'] = nDefectCount;

        // 측정 결과 요약
        nAvg = nTotal / nInputCnt;   //평균
        nMax = Math.max.apply(null,aMeansArray);
        nMin = Math.min.apply(null,aMeansArray);

        let point = Number(inspDatas[selectedIdx - 1]['POINT']);
        nAvg = Number(nAvg.toFixed(point));
        nMax = Number(nMax.toFixed(point));
        nMin = Number(nMin.toFixed(point));

        inspDatas[selectedIdx - 1]['MEAS_AVG'] = nAvg;
        inspDatas[selectedIdx - 1]['MEAS_MAX'] = nMax;
        inspDatas[selectedIdx - 1]['MEAS_MIN'] = nMin;

        if (nDefectCount > 0) {   //불량
            fn_addDefect(selectedIdx - 1, null);

            inspDatas[selectedIdx - 1]['MEAS_RSLT'] = "N";

            setDataset("secMeasRslt", defaultSecMeasRslt.filter((item) => item.CD != '1')); // 콤보에서 합격 지움
        } else if (inspDatas[selectedIdx - 1]['SAM_CNT'] != "0" && (nPassCount == inspDatas[selectedIdx - 1]['SAM_CNT'])) {   //OK
            inspDatas[selectedIdx - 1]['MEAS_RSLT'] = "Y";
        } else {  //
            inspDatas[selectedIdx - 1]['MEAS_RSLT'] = "";

            let specId = inspDatas[selectedIdx - 1]['SPEC_ID'];

            for (let j = 0; j < vds_Defect.length; j++) {
                if (specId == vds_Defect[j]['SPEC_ID']) {
                    vds_DefectDelete.push(vds_Defect[j]);
                    vds_Defect = vds_Defect.filter((item) => item["SPEC_ID"] != specId);
                }
            }
        }

        // 전부 합격일때 전체판정결과 합격으로 표시
        for (let i = 0; i < inspDatas.length; i++) {
            if (inspDatas[i]['MEAS_RSLT'] != 'Y') {

            } else { // 각각 spec 합격으로 판정되면 defect 지운다
                nPassSpecCnt++;

                let specId = inspDatas[i]['SPEC_ID'];

                for (let j = 0; j < vds_Defect.length; j++) {
                    if (specId == vds_Defect[j]['SPEC_ID']) {
                        vds_DefectDelete.push(vds_Defect[j]);
                        vds_Defect = vds_Defect.filter((item) => item["SPEC_ID"] != specId);
                    }
                }
            }
        }

        if (vds_Defect.length == 0) {  // 불량이 없으면
            setDataset("secMeasRslt", defaultSecMeasRslt);
        }

        setInspData(inspDatas);
        setDataset("ds_Defect", vds_Defect);
        setDataset("ds_DefectDelete", vds_DefectDelete);

        fn_checkMeasCheck();
    }

    /* 콤보일때 결과값 체크 */
    function fn_setResultSpec(row: number) {
        let sResult = "";   // 판정결과
        let nDefectCount = 0;   //불량수
        let nPassCount = 0;     //OK 수
        let nPassSpecCnt = 0;
        let inspTotalCnt = 0; // 검사수

        //row 의 전체 cell 검사
        for (let i = 0; i < Number(inspDatas[row]['SAM_CNT']); i++) {
            if (inspDatas[row][vds_grdColList[i]['BIND_COL']] == "0") {
                nDefectCount++;
                inspTotalCnt++;
            } else if (inspDatas[row][vds_grdColList[i]['BIND_COL']] == "1") {
                nPassCount++;
                inspTotalCnt++;
            }
        }

        inspDatas[row]['INSP_TOTAL_CNT'] = inspTotalCnt; // 검사수
        inspDatas[row]['MEAS_DEFECT_CNT'] = nDefectCount;

        if (nDefectCount > 0) {   //불량
            fn_addDefect(row, null);

            inspDatas[row]['MEAS_RSLT'] = "N";

            setDataset("secMeasRslt", defaultSecMeasRslt.filter((item) => item.CD != '1')); // 콤보에서 합격 지움
        } else if (inspDatas[row]['SAM_CNT'] != "0" && (nPassCount == Number(inspDatas[row]['SAM_CNT']))) { //OK
            inspDatas[row]['MEAS_RSLT'] = "Y";
        } else {
            inspDatas[row]['MEAS_RSLT'] = "";

            let specId = inspDatas[row]['SPEC_ID'];

            for (let j = 0; j < vds_Defect.length; j++) {
                if (specId == vds_Defect[j]['SPEC_ID']) {
                    vds_DefectDelete.push(vds_Defect[j]);
                    vds_Defect = vds_Defect.filter((item) => item["SPEC_ID"] != specId);
                }
            }
        }

        let sampleCntOverzero = 0;

        // 전부 합격일때 전체판정결과 합격으로 표시
        for (let i = 0; i < inspDatas.length; i++) {
            if (inspDatas[i]['MEAS_RSLT'] != 'Y') {

            } else { // 각각 spec 합격으로 판정되면 defect 지운다
                nPassSpecCnt++;

                let specId = inspDatas[i]['SPEC_ID'];

                for (let j = 0; j < vds_Defect.length; j++) {
                    if (specId == vds_Defect[j]['SPEC_ID']) {
                        vds_DefectDelete.push(vds_Defect[j]);
                        vds_Defect = vds_Defect.filter((item) => item["SPEC_ID"] != specId);
                    }
                }
            }

            if (Number(inspDatas[i]['SAM_CNT']) > 0) {
                sampleCntOverzero++;
            }
        }

        // let tsiPassCnt = 0;
        // let tsiInspCnt = this.ds_tsiSpecialInspList.rowcount;
        //
        // for(var i=0; i<this.ds_tsiSpecialInspList.rowcount; i++) {
        //     if(this.ds_tsiSpecialInspList.getColumn(i, "MEAS_RESULT") == "Y") {
        //         tsiPassCnt++;
        //     }
        // } // tsi 관련 로직 주석

        if (nPassSpecCnt == inspDatas.length) {  // 전체합격
            if (fv_isDivisionQ)
            {
                setDataset("secMeasRslt", defaultSecMeasRslt.filter((item) => item.CD == '1' || item.CD == 'H'));
                vds_Part[0]['MEAS_RESULT'] = "1";
                vds_Part[0]['SEC_MEAS_RESULT'] = "1";
            }
            else
            {
                setDataset("secMeasRslt", defaultSecMeasRslt.filter((item) => item.CD == '1'));
                vds_Part[0]['MEAS_RESULT'] = "1";
                vds_Part[0]['SEC_MEAS_RESULT'] = "1";
            }
        }

        setInspData(inspDatas);
        setDataset("ds_Part", vds_Part);
        setDataset("ds_Defect", vds_Defect);
        setDataset("ds_DefectDelete", vds_DefectDelete);
    }

    /* 불량판정내용요약 추가 */
    async function fn_addDefect(specRow: number, defectCnt: any) {
        let sSpectId = inspDatas[specRow]['SPEC_ID'];      //불량 선택된 검사항목의 specId
        let sSpectRev = Number(inspDatas[specRow]['SPEC_REV']);    //불량 선택된 검사항목의 specRev
        let addRowYn = true;                                            //로우 추가 여부
        let nDefectRow = vds_Defect.length;                       //현재 불량판정내용요약 총 row수
        let sDefectRowType = "I";                                       //불량판정내용요약 rowtype(S,I,U,D)
        let nRow = 0;                                                   //추가해야할 row

        for (let i = 0; i < nDefectRow; i++) {
            let sDSpectId = vds_Defect[i]['SPEC_ID'];
            let sDSpectRev = Number(vds_Defect[i]['SPEC_REV']);

            if (sSpectId == sDSpectId && sSpectRev == sDSpectRev) {
                addRowYn = false;
                nRow = i;
            }
        }

        // fn_defectGroupSeclect().then((response) => {
        //     setDataset("ds_DefectGroup", response?.data.ds_DefectGroup);
        //     setDataset("ds_DefectSymptom2", response?.data.ds_DefectSymptom2);
        // }).catch(e => {
        //     throwException(e);
        //     return new Promise(() => {
        //     });
        // });

        if (addRowYn) {
            vds_Defect.push({
                ROW_TYPE: sDefectRowType,
                SPEC_ID: sSpectId,
                SPEC_REV: sSpectRev,
                EQUIP_CD: inspDatas[specRow]['MEQUIP_CD'],
                EQUIP_NM: inspDatas[specRow]['MEQUIP_NM'],
                DEFECT_CNT: gfn_isNull(defectCnt) ? inspDatas[specRow]['MEAS_DEFECT_CNT'] : defectCnt,
                PARA_CD: inspDatas[specRow]['PARA_CD'],
                PARA_NM: inspDatas[specRow]['PARA_NM']
            });
        } else {
            vds_Defect[nRow]['DEFECT_CNT'] = inspDatas[specRow]['MEAS_DEFECT_CNT'];
        }

        // vds_Defect.push({
        //     ROW_TYPE: sDefectRowType,
        //     SPEC_ID: sSpectId,
        //     SPEC_REV: sSpectRev,
        //     EQUIP_CD: inspDatas[specRow]['MEQUIP_CD'],
        //     EQUIP_NM: inspDatas[specRow]['MEQUIP_NM'],
        //     DEFECT_CNT: gfn_isNull(defectCnt) ? inspDatas[specRow]['MEAS_DEFECT_CNT'] : defectCnt,
        //     PARA_CD: inspDatas[specRow]['PARA_CD'],
        //     PARA_NM: inspDatas[specRow]['PARA_NM']
        // });

        setDataset("ds_Defect", vds_Defect);
    }

    /* 불량그룹조회 */
    function fn_defectGroupSeclect() {
        const requestJson: NexacroJsonType = {
            ds_Part: vds_Part
        };

        return sendAxios('/common/nexacro/QMV_P_Out_InspectVndRcrdDetailRegDefectGroupSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    /* 검사방식 계산 */
    function fn_getSampleCntByMeasMth(measMth: string) {
        let rtnSamCnt = 0;
        vds_AllSample = vds_AllSample.filter(v => v.MEAS_MTH == measMth);

        if (measMth.substr(0, 1)  == "A") { //전수검사 case
            rtnSamCnt = fv_Qty;
        } else if(measMth.substr(0, 1) == "C") {
            rtnSamCnt = Number(vds_AllSample[0]["SAM_CNT"]);

            if (fv_Qty < rtnSamCnt) {
                rtnSamCnt = fv_Qty;
            }
        } else {
            for (let i = 0; i < vds_AllSample.length; i++) {
                let fromSize = Number(vds_AllSample[i]["FROM_SIZE"]);
                let toSize = Number(vds_AllSample[i]["TO_SIZE"]);

                if(fv_Qty >= fromSize && fv_Qty <= toSize){
                    rtnSamCnt = Number(vds_AllSample[i]["SAM_CNT"]);

                    break;
                }
            }
        }

        if (vds_Part[0]['GROUP_YN'] == "Y") {
            if (rtnSamCnt > parseFloat(vds_Part[0]['REAL_GR_QTY'])) {
                rtnSamCnt = vds_Part[0]['REAL_GR_QTY'];
            }
        }

        //소수점 수량 오류에 따른 변경 by KKA
        rtnSamCnt = Math.ceil(rtnSamCnt);

        return rtnSamCnt;
    }

    /* AQL 계산 */
    function fn_getSampleCntByAql(aql: string, samCnt: number) {
        let rtnSamCnt = 0;
        vds_Aql = vds_Aql.filter(v => v.AQL_LEVEL == aql);
        let idx = vds_Aql.findIndex(v => v.AQL_SAMPLE_CNT == samCnt);

        if (idx > -1) {
            let iterator = vds_Aql[idx]["ACCESS_PATH"];

            if (iterator == "U") {
                while(iterator != "OK"){
                    iterator = vds_Aql[idx]["ACCESS_PATH"];
                    rtnSamCnt = Number(vds_Aql[idx]["AQL_SAMPLE_CNT"]);

                    idx--;
                }
            } else if(iterator == "D") {
                while(iterator != "OK") {
                    iterator = vds_Aql[idx]["ACCESS_PATH"];
                    rtnSamCnt = Number(vds_Aql[idx]["AQL_SAMPLE_CNT"]);

                    idx++;
                }
            } else {
                rtnSamCnt = Number(vds_Aql[idx]["AQL_SAMPLE_CNT"]);
            }
            if (fv_Qty < rtnSamCnt) {
                rtnSamCnt = fv_Qty;
            }
        } else {
            rtnSamCnt = samCnt;
        }

        if (vds_Part[0]['GROUP_YN'] == "Y") {
            if (rtnSamCnt > parseFloat(vds_Part[0]['REAL_GR_QTY'])) {
                rtnSamCnt = vds_Part[0]['REAL_GR_QTY'];
            }
        }

        //소수점 수량 오류에 따른 변경 by KKA
        rtnSamCnt = Math.ceil(rtnSamCnt);

        return rtnSamCnt;
    }

    /* 샘플수 변화시 그리드 다시 그림 */
    function fn_redrawGrid(row: number, newsam: number) {
        let nMaxSam = 0;
        let nSam = 0;
        let nDefCnt = isNaN(Number(inspDatas[row]['MEAS_DEFECT_CNT'])) ? 0 : Number(inspDatas[row]['MEAS_DEFECT_CNT']); // 기존 불량 수

        inspDatas[row]['MEAS_RSLT'] = "-";

        if (newsam < Number(inspDatas[row]['SAM_CNT'])) {
            //기존 spec 입력된 측정값 제거
            for (let i= 0; i < Number(inspDatas[row]['SAM_CNT']) - newsam; i++) {
                if (!gfn_isNull(inspDatas[row]['RESULT_YN_' + (i + 1 + newsam)]) && Number(inspDatas[row]["RESULT_YN_" + (i + 1 + newsam)]) == 0) {   // 제거된게 불량이면 불량제거
                    nDefCnt--;

                    inspDatas[row]['MEAS_DEFECT_CNT'] = nDefCnt;
                }

                inspDatas[row]['MEAS_VAL_' + (i + 1 + newsam)] = "";
                inspDatas[row]['RESULT_YN_' + (i + 1 + newsam)] = "";

            }
        }

        // 불량수에 따른 판정결과 새로고침
        for (let i = 0; i < vds_Defect.length; i++) {
            if (vds_Defect[i]['SPEC_ID'] == inspDatas[row]['SPEC_ID']) {
                vds_Defect[i]['DEFECT_CNT'] = nDefCnt;

                if (nDefCnt == 0) {
                    vds_DefectDelete.push(vds_Defect[i]);
                    vds_Defect = vds_Defect.filter((item) => item["SPEC_ID"] != vds_Defect[i]['SPEC_ID']);
                }
            }
        }

        let allOk = true;

        if (nDefCnt == 0) {
            for (let i = 0; i < newsam; i++) {
                if (gfn_isNull(inspDatas[row]['RESULT_YN_' + (i + 1)])) {
                    allOk = false;

                    inspDatas[row]['MEAS_RSLT'] = "-";
                }
            }
            if (allOk)
            {
                inspDatas[row]['MEAS_RSLT'] = "Y";
                inspDatas[row]['MEAS_DEFECT_CNT'] = 0;
            }
            else
            {
                inspDatas[row]['MEAS_DEFECT_CNT'] = "-";
                inspDatas[row]['MEAS_RSLT'] = "-";
            }
        } else {
            if (nDefCnt > 0) {
                inspDatas[row]['MEAS_RSLT'] = "N";
            }
        }

        inspDatas[row]['SAM_CNT'] = String(newsam);

        let inspTotalCnt = 0; // 검사수

        for (let i = 0; i < Number(inspDatas[row]['SAM_CNT']); i++) {
            if (inspDatas[row]['MEAS_VAL_' + (i + 1)] != undefined && inspDatas[row]['MEAS_VAL_' + (i + 1)] != '') {
                inspTotalCnt++;
            }
        }

        inspDatas[row]['INSP_TOTAL_CNT'] = inspTotalCnt;

        // 평균, min max 다시계산
        fn_CalSummary();

        if (newsam == 0) {
            inspDatas[row]['MEAS_MIN'] = "-";
            inspDatas[row]['MEAS_MAX'] = "-";
            inspDatas[row]['MEAS_AVG'] = "-";
            inspDatas[row]['MEAS_RSLT'] = "-";
            inspDatas[row]['MEAS_DEFECT_CNT'] = "-";
        }

        for (let i = 0; i < inspDatas.length; i++) {
            nSam = inspDatas[i]['SAM_CNT'];

            if (Number(nMaxSam) < nSam) {
                nMaxSam = nSam;
            }
        }

        let oldMaxSam = vds_grdColList.length;

        if (Number(nMaxSam) > oldMaxSam) { // 수정된 max Sample Count 로 데이터셋 수정
            for (let i = 0; i < Number(nMaxSam) - oldMaxSam; i++) {
                const tempInspDatas = inspDatas.map(data => ({
                    ...data,
                    ['MEAS_VAL_'+(oldMaxSam + i + 1)]: "",
                    ['RESULT_YN_'+(oldMaxSam + i + 1)]: ""
                }));

                inspDatas = [...tempInspDatas];

                vds_grdColList.push({
                    COL_NAME: "#" + (oldMaxSam + i + 1),
                    BIND_COL: "MEAS_VAL_" + (oldMaxSam + i + 1)
                });
            }
        } else if (Number(nMaxSam) < oldMaxSam) {
            for (let i = 0; i < oldMaxSam - Number(nMaxSam); i++) {
                delete inspDatas[row]["MEAS_VAL_"+(Number(nMaxSam) + i + 1)];
                delete inspDatas[row]["RESULT_YN_"+(Number(nMaxSam) + i + 1)];
                vds_grdColList = vds_grdColList.filter((item) => item["BIND_COL"] != "MEAS_VAL_"+(Number(nMaxSam) + i + 1));
            }
        }

        setFv_maxSamCnt(nMaxSam);
        setInspData(inspDatas);
        setDataset("ds_grdColList", vds_grdColList);
        setDataset("ds_Spec", inspDatas);
        setDataset("ds_Defect", vds_Defect);
        setDataset("ds_DefectDelete", vds_DefectDelete);
    }

    function fn_CalSummary() {
        //평균, min, max 값 구하기
        for (let i = 0; i < inspDatas.length; i++) {
            if (inspDatas[i]['NUM_YN'] == "Y" && Number(inspDatas[i]['SAM_CNT']) > 0) {
                let nMax = 0;
                let nMin = 0;
                let nAvg = 0;
                let nTotal = 0;
                let nInputCnt = Number(inspDatas[i]['SAM_CNT']);
                let nInputCntTmp = 0;
                let aMeansArray = [];

                for (let j = 0; j < nInputCnt; j++) {
                    if (inspDatas[i]['MEAS_VAL_' + (j + 1)] == undefined || inspDatas[i]['MEAS_VAL_' + (j + 1)] == null || inspDatas[i]['MEAS_VAL_' + (j + 1)] == '') {
                        continue;
                    }

                    nInputCntTmp++;

                    let val = Number(inspDatas[i]['MEAS_VAL_' + (j + 1)]);
                    nTotal += val;
                    aMeansArray.push(val);
                }

                if (nInputCntTmp > 0) {
                    nAvg = nTotal / nInputCntTmp;   //평균
                    nMax = Math.max.apply(null, aMeansArray);
                    nMin = Math.min.apply(null, aMeansArray);

                    let point = Number(inspDatas[i]['POINT']);

                    nAvg = Number(nAvg.toFixed(point));
                    nMax = Number(nMax.toFixed(point));
                    nMin = Number(nMin.toFixed(point));
                    inspDatas[i]['MEAS_AVG'] = nAvg;
                    inspDatas[i]['MEAS_MAX'] = nMax;
                    inspDatas[i]['MEAS_MIN'] = nMin;
                }
            }
        }
    }

    /* 판정 결과 체크 */
    function fn_checkMeasCheck() {
        let nPassSpecCnt = 0;

        let sampleCntOverzero = 0;

        // 전부 합격일때 전체판정결과 합격으로 표시
        for (let i = 0; i < inspDatas.length; i++) {
            if (inspDatas[i]['MEAS_RSLT'] == 'Y') {
                nPassSpecCnt++;
            }

            if (Number(inspDatas[i]['SAM_CNT']) > 0) {
                sampleCntOverzero++;
            }
        }

        // 합격
        if (nPassSpecCnt == inspDatas.length) {  // 전체합격
            /** VOC 41 Start : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/
            /** 전체 합격일 경우 Hold 도 선택 가능 **/
            if (fv_isDivisionQ)
            {
                setDataset("secMeasRslt", defaultSecMeasRslt.filter((item) => item.CD == '1' || item.CD == 'H'));
                vds_Part[0]['MEAS_RESULT'] = "1";
                vds_Part[0]['SEC_MEAS_RESULT'] = "1";
            }
            else
            {
                setDataset("secMeasRslt", defaultSecMeasRslt.filter((item) => item.CD == '1')); // 콤보에 합격만 표시
                vds_Part[0]['MEAS_RESULT'] = "1";
                vds_Part[0]['SEC_MEAS_RESULT'] = "1";
            }
            /** VOC 41 End   : 2019.09.05 채보람 Smart Inspection Data 연계 입고 Lot 자동판정 **/

            // 판전완료 되지 않음
        } else if (vds_Defect.length == 0) {
            setDataset("secMeasRslt", defaultSecMeasRslt);
            vds_Part[0]['MEAS_RESULT'] = "";
            // NG
        } else {
            setDataset("secMeasRslt", defaultSecMeasRslt.filter((item) => item.CD != '1')); // 콤보에서 합격 지움
            vds_Part[0]['MEAS_RESULT'] = "";
        }

        setDataset("ds_Part", vds_Part);
    }

    /* 변경값 useState 적용 */
    function setInspValData(e: any, val: string) {
        let targetVal = (val == '' ? e.target.value : val);

        let copyArray = [...inspDatas];
        copyArray[selectedIdx - 1][e.target.name] = targetVal;
        setInspData(copyArray);
    }

    const handleChange = (e: any, index :number, columnN :string, columNResult :string) => {
        let value = e.target.value;
        let copyArray = [...inspDatas];
        copyArray[index][columnN] = value;
        copyArray[index][columNResult] = value;

        /* OK 일괄 적용 */
        for (let i = 0; i < Number(copyArray[index]['SAM_CNT']); i++) {
            if ((copyArray[index]["MEAS_VAL_" + (i + 1)] == undefined || copyArray[index]["MEAS_VAL_" + (i + 1)] == null || copyArray[index]["MEAS_VAL_" + (i + 1)] == '') && columnN != ("MEAS_VAL_" + (i + 1))) {
                copyArray[index]["MEAS_VAL_" + (i + 1)] = "1";
                copyArray[index]["RESULT_YN_" + (i + 1)] = "1";
            }
        }

        setInspData(copyArray);
        setDataset("ds_Spec", copyArray);

        // SPEC 일반 입력값 입력시
        ds_Spec_onvaluechanged();
    }

    function lastHidden() {
        // 마지막 입력칸일 경우 포커스를 다음페이지로 이동
        const link: HTMLElement = document.getElementsByClassName('swiper-next')[0] as HTMLElement;
        link.click();
    }

    return (
        <>
            <div className={'pop-layer'} style={{display: 'block'}}>
                <div className={'wrap'}>
                    <header>
                        <h1>{getDomain('DOMAIN4669')}</h1>  {/*입고검사 성적서 작성*/}
                            <span className={'btn-wrap right'}>
                            <button type={'button'} className={'button-close'} onClick={() => onClose(inspData)}>
                                <i className={'hidden'}>{getDomain('DOMAIN0173')}</i>   {/*닫기*/}
                            </button>
                        </span>
                    </header>
                    <div className={'container-noheader'}>
                        <div className={'comm-slide-wrap'} style={{
                            display: 'block',
                            // position: 'absolute',
                            backgroundColor: 'white',
                            width: '100%',
                            height: 'unset'
                        }}>
                            <div className={'swiper-pagination'} style={{
                                width: '100% !important',
                                paddingBottom: '2rem'
                            }}></div>
                            <div className="pagination-type2"><em>{selectedIdx}</em>/<span>{list.length}</span></div>

                            {/*23.11.06 네비게이션 기능 추가*/}
                            <div className={'swiper-next'}></div>
                            <div className={'swiper-prev'}></div>
                            <Swiper
                                modules={[Pagination, Navigation]}
                                pagination={{
                                    dynamicBullets: true,
                                    el: '.swiper-pagination',
                                    clickable: true
                                }}
                                // onSwiper={swiper => console.log(swiper)}
                                onSlideChange={(swiper) => setSelectedIdx(swiper.activeIndex + 1)}
                                initialSlide={selected || 0}
                                style={{marginBottom:'230px'}}
                                className={'swiper mySwiper'}
                                navigation={{
                                    nextEl: ".swiper-next",
                                    prevEl: ".swiper-prev",
                                }}
                            >
                                {
                                    inspData.map((item, index) => {
                                        return <SwiperSlide key={item.PARA_CD+index}>
                                            <div className={'swiper-slide'}>
                                                <ul className={'contents-table-list'}>
                                                    <li>
                                                        <div className={'col'}>
                                                            <span className={'title'}>{getDomain('DOMAIN2048')}</span>    {/*검사항목*/}
                                                            <span className={'item-wrap'}>{item.PARA_NM}
                                                                {!gfn.gfn_isNull(item.ATTACH_FILE_ID) ?
                                                                    <button type={'button'} className={'btn-gallary'} onClick={() => goPictureView([
                                                                        process.env.REACT_APP_HTTP_BASE_URL+'/common/nexacro/commonGetImageFile.do?fileId='+item.ATTACH_FILE_ID
                                                                    ])}>
                                                                    <i className={'hidden'}>{getDomain('DOMAIN4584')}</i>  {/*이미지*/}
                                                                    </button> : <></>
                                                                }
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className={'col'}>
                                                            <span className={'title'}>{getDomain('DOMAIN2050')}</span>  {/*검사방법*/}
                                                            <span className={'item-wrap'}>{item.MEQUIP_NM}</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className={'col'}>
                                                            <span className={'title'}>LSL</span>
                                                            <span className={'item-wrap right'}>{item.LSL}</span>
                                                        </div>
                                                        <div className={'col'}>
                                                            <span className={'title'}>USL</span>
                                                            <span className={'item-wrap right'}>{item.USL}</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className={'col'}>
                                                            <span className={'title'}>CL</span>
                                                            <span className={'item-wrap right'}>{item.CL}</span>
                                                        </div>
                                                        <div className={'col'}>
                                                            <span className={'title'} dangerouslySetInnerHTML={{__html:getDomain('DOMAIN2055')}}></span>  {/*측정단위*/}
                                                            <span className={'item-wrap right'}>{item.UNIT}</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className={'col'}>
                                                            <span className={'title'}>{getDomain('DOMAIN4670')}</span>  {/*검사방식*/}
                                                            <span className={'item-wrap'}>
                                                                {
                                                                    pv_flag == "INS"
                                                                    ?
                                                                        <select
                                                                            value={item.SEC_INSP_MTH as string}
                                                                            name={'SEC_INSP_MTH'}
                                                                            onChange={changeInspMth}
                                                                            disabled={pv_flag != "INS"}>
                                                                            {
                                                                                (dataset?.ds_CommonCode || [])
                                                                                    .filter((i, index) => i.CATEGORY === "SQCI000020" && i.LANG === gv_locale)
                                                                                    .map((i, index) => {
                                                                                        return <option key={i.CD} value={i.CD as string}>{i.CDNM}</option>;
                                                                                    })
                                                                            }
                                                                        </select>
                                                                    :
                                                                        item.SEC_INSP_MTH
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="col">
                                                            <span className={'title'}>AQL</span>
                                                            <span className={'item-wrap'}>
                                                                {
                                                                    item.SEC_INSP_MTH.substr(0, 1) == "C" || item.SEC_INSP_MTH.substr(0, 1) == "A"
                                                                    ?
                                                                        ''
                                                                    :
                                                                        pv_flag == "INS"
                                                                        ?
                                                                            <select
                                                                                value={item.SEC_AQL_LEVEL as string}
                                                                                name={'SEC_AQL_LEVEL'}
                                                                                onChange={changeInspAql}
                                                                                disabled={pv_flag != "INS"}>
                                                                                <option value={""}>==</option>
                                                                                {
                                                                                    (dataset?.ds_CommonCode || [])
                                                                                        .filter((i, index) => i.CATEGORY === "SQCI000100" && i.LANG === gv_locale)
                                                                                        .map((i, index) => {
                                                                                            return <option key={i.CD} value={i.CD as string}>{i.CDNM}</option>;
                                                                                        })
                                                                                }
                                                                            </select>
                                                                        :
                                                                            item.SEC_AQL_LEVEL
                                                                }
                                                            </span>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className={'table-wrap'}>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{width: '20%'}}/>
                                                            <col/>
                                                        </colgroup>
                                                        <thead>
                                                        <tr>
                                                            <th scope={'col'}>No</th>
                                                            <th scope={'col'}>
                                                                <strong className={'required'}>*</strong>{getDomain('DOMAIN4633')} {/*측정결과*/}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Array.from({length: Number(item.SAM_CNT)}, (v,i) => i).map((item2, index2) => {
                                                                    return <tr key={item.PARA_CD+index+index2}>
                                                                        <td>{index2+1}</td>
                                                                        <td className={'center'}>
                                                                            {
                                                                                item.NUM_YN === 'Y'
                                                                                ?   (isIOS?
                                                                                        <input type={'text'} disabled={pv_flag != "INS"} className={'result-right'} defaultValue={item['MEAS_VAL_'+(index2+1)] as string}
                                                                                        ref={(el:HTMLInputElement) => targets.current.push({ index: index, index2: index2 , el: el})}
                                                                                        onInput={(e) => changeInspVal(e, index, index2, 'MEAS_VAL_'+(index2+1))}
                                                                                        name={'MEAS_VAL_'+(index2+1)}
                                                                                        pattern={'-?\d\*.?\d+'}
                                                                                        onBlur={(e) => onInputBlur(e)}
                                                                                        /> 
                                                                                    :
                                                                                        <input type={'text'} disabled={pv_flag != "INS"} className={'result-right'} defaultValue={item['MEAS_VAL_'+(index2+1)] as string}
                                                                                        ref={(el:HTMLInputElement) => targets.current.push({ index: index, index2: index2 , el: el})}
                                                                                        onInput={(e) => changeInspVal(e, index, index2, 'MEAS_VAL_'+(index2+1))}
                                                                                        name={'MEAS_VAL_'+(index2+1)}
                                                                                        inputMode={"numeric"}
                                                                                        onBlur={(e) => onInputBlur(e)}
                                                                                        />
                                                                                    )
                                                                                :
                                                                                    <div className={'radio-item'}>
                                                                                        {
                                                                                            ds_Decision02.map((a, aindex) => {
                                                                                                return <label key={a.CODE} style={{
                                                                                                    marginRight: '4rem',
                                                                                                    fontSize: '1.4rem'
                                                                                                }}>
                                                                                                    <input type="radio"
                                                                                                           style={{
                                                                                                               width: '2rem',
                                                                                                               height: '2rem'
                                                                                                           }}
                                                                                                           disabled={pv_flag != "INS"} value={a.CODE as string} checked={item['MEAS_VAL_'+(Number(index2)+1)] as string == a.CODE as string}
                                                                                                        // onChange={changeInspVal} name={index+'MEAS_VAL_'+(index2+1)}
                                                                                                           onChange={(e) => {handleChange(e, index, 'MEAS_VAL_'+(Number(index2)+1), 'RESULT_YN_'+(Number(index2)+1))}}
                                                                                                    />{a.NAME}</label>

                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            {
                                                                inspData.length != (index + 1)
                                                                ?
                                                                    <input type={"text"} onFocus={lastHidden} style={{border: "0px solid #FFF", width:"1px", height:"1px"}} />
                                                                : null
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className={'btn-area bottom'}>
                            <button type={'button'} className={'button-primary'} style={{height: '4.9rem', fontSize: '1.8rem'}} onClick={() => onClose(inspData)}>{getDomain('DOMAIN0173')}</button>   {/*닫기*/}
                        </div>
                    </div>
                </div>
            </div>
            <CSSTransition in={showPicture} classNames={showPicture ? 'right' : 'left'} timeout={500} unmountOnExit>
                <Picture pathList={imagePath || []} onClose={closePicture}/>
            </CSSTransition>
        </>
    );
};